<template lang="pug">
  .competitors-modal
    .price-column
      .price-column-data
        .filters-data
          .shop {{ filters.shop.name }}
          .options
            .option.ota {{ filters.ota.name }}
            .option.insurance {{ $t(`price_calendar.filters.toggler.${filters.insurance.title}`) }}
        hr
        .date
          .label
            span {{ $t("price_calendar.calendar.price_modal.period_starts") }}
            .m-l-10.m-r-10 -
            span {{ $t("price_calendar.calendar.price_modal.period_ends") }}
          .value
            AppDatepicker(
              range
              v-model="dates"
              :clearable="false"
              :disabled-date="disabledDate"
            )
        .apply-recommended-prices-checkbox(v-if="isAdvancedMode")
          AppCheckbox(v-model="isCommonPrice")
          .title {{ $t("price_calendar.calendar.price_modal.set_prices_once") }}
        .price
          | {{ $t("price_calendar.calendar.price_modal.price") }}
          .value
            AppNumberInput(
              autoselect
              autofocus
              v-model="price"
              :disabled="!isCommonPrice"
              :invalid="isInvalidPrice"
              :max="Number.MAX_SAFE_INTEGER"
            )
            .yen ¥
      .save
        AppButton(
          :disabled="isSaveDisabled"
          title="price_calendar.calendar.price_modal.save"
          @click="handleSave"
        )
        .tip
          FaIcon(icon="info-circle")
          | {{ $t("price_calendar.calendar.price_modal.not_reflected_on_ota") }}
    .competitors-info
      BTabs
        BTab(
          :title="$t('price_calendar.calendar.price_modal.competitors')"
          key="competitors"
        )
          CompetitorsTab(
            :initial-dates="dates"
            :filters="filters"
            :rental-period="rentalPeriod"
            :current-plan="currentPlan"
            :current-prices="currentPrices"
            :recommended-prices="recommendedPrices"
            :changed-prices="changedPricesManuallyChanged"
            :competitors="competitors"
            :is-common-price="isCommonPrice"
            @change="handleChangePrice"
          )
        BTab(
          :title="$t('price_calendar.calendar.price_modal.reservations')"
          key="reservations"
        )
          ReservationsTab(
            :start-date="dates[0]"
            :reservations="reservations"
          )
</template>

<script>
  import { getFormattedDateRange } from "@/helpers/dates"
  import { reduce } from "lodash-es"
  import { bus } from "@/config"
  import { ENTER_KEY_CODE } from "@/config/constants"
  import { startOfDay, endOfDay } from "date-fns"
  import { isAdvancedMode } from "@/helpers/price-calendar"

  export default {
    props: {
      initialPrice: {
        type: Object,
        default: () => ({
          value: 0,
          applied_value: 0
        })
      },
      initialStartDate: {
        type: Date,
        required: true
      },
      initialEndDate: {
        type: Date,
        required: true
      },
      carClassId: {
        type: Number,
        required: true
      },
      rentalPeriod: {
        type: String,
        required: true
      },
      filters: Object,
      currentPrices: Object,
      recommendedPrices: Object,
      changedPrices: Object,
      competitors: Array,
      reservations: Object,
      currentPlan: Object
    },

    components: {
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppButton: () => import("@/components/elements/AppButton"),
      CompetitorsTab: () => import("./CompetitorsTab"),
      ReservationsTab: () => import("./ReservationsTab")
    },

    mounted() {
      document.addEventListener("keyup", this.handleKeyup)
    },

    beforeDestroy() {
      document.removeEventListener("keyup", this.handleKeyup)
    },

    computed: {
      isAdvancedMode,

      isInvalidPrice({ price }) {
        return price <= 0
      },

      isSaveDisabled({ isInvalidPrice, isCommonPrice }) {
        return isCommonPrice && isInvalidPrice
      }
    },

    data() {
      return {
        price: this.initialPrice.value,
        dates: [this.initialStartDate, this.initialEndDate],
        isCommonPrice: !isAdvancedMode(),
        changedPricesManuallyChanged: { ...this.changedPrices }
      }
    },

    methods: {
      disabledDate(date) {
        const { open_start_date, open_end_date } = this.currentPlan

        return date < startOfDay(new Date(open_start_date)) || date > endOfDay(new Date(open_end_date))
      },

      handleKeyup(e) {
        if (e.keyCode === ENTER_KEY_CODE) this.handleSave()
      },

      handleChangePrice({ date, value }) {
        this.changedPricesManuallyChanged = { ...this.changedPricesManuallyChanged, [date]: value }
      },

      handleSave() {
        if (this.isSaveDisabled) return

        const prices = this.getResultPrices()
        bus.emit(`update-prices-${this.rentalPeriod}`, prices)

        this.$emit("close")
      },

      getResultPrices() {
        if (this.isCommonPrice) {
          const dateRange = getFormattedDateRange(...this.dates)
          return this.getMappedPrices(dateRange)
        } else {
          return this.changedPricesManuallyChanged
        }
      },

      getMappedPrices(dateRange) {
        return reduce(
          dateRange,
          (obj, date) => {
            obj[date] = this.price

            return obj
          },
          {}
        )
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/price-calendar.sass"

  .competitors-modal
    display: flex
    font-size: 0.8rem

    .price-column
      position: relative
      height: 550px
      width: 320px

      .price-column-data
        height: 495px

      .filters-data
        .shop
          color: $default-gray
          margin-bottom: 10px

        .options
          display: flex

          .option
            border: 1px solid $default-purple
            border-radius: 10px
            color: $default-purple
            margin-right: 5px
            padding: 0 5px
            text-align: center
            width: auto

      .apply-recommended-prices-checkbox
        display: flex
        margin-bottom: 20px

        .app-checkbox
          padding: 0

      .price
        align-items: center
        display: flex
        justify-content: flex-end

        .value
          margin-left: 10px

      .date,
      .price
        margin-bottom: 20px

        .label
          align-items: center
          display: flex
          font-weight: 500
          margin-bottom: 10px

        .value
          align-items: center
          display: flex

          ::v-deep
            .app-number-input
              .field
                +shadowed-element
                border: 1px solid $border-element-color
                border-radius: 5px
                height: 38px
                font-size: 0.8rem
                padding: 10px
                text-align: left
                width: 150px

            .datepicker
              padding: 0
              width: 100%

              .mx-input
                +shadowed-element

          .yen
            font-size: 1.25em
            margin-left: 10px

      .save
        text-align: right

        .app-button
          +shadowed-element

          background: $default-purple
          color: $default-white
          font-size: 0.8rem

        .tip
          +tip-with-icon
          margin-top: 10px

    .competitors-info
      height: 550px
      margin-left: 30px
      width: 800px

      ::v-deep
        .competitors-tab,
        .reservations-tab
          background-color: $default-white
          box-shadow: 2px 2px 4px -1px $default-gray-medium
          height: 520px
          padding: 20px

        .nav-tabs
          border-bottom: 0

          .nav-link
            border: 0
            box-shadow: 2px 0 2px -2px $default-gray, -2px 0 2px -2px $default-gray
            color: $default-black
            margin-right: 2px

            &:active
              outline: none

            &.active
              background: $default-white
              border: 0
              box-shadow: none
              color: $default-purple
              font-weight: 600
</style>
