<template lang="pug">
  .rental-period-days(:class="{ large: isLargeClass }")
    .by-day(
      v-for="date, index in formattedDates"
      :key="date"
      :class="{ applying: appliedValueByDate(date) !== valueByDate(date) }"
    )
      .applying-overlay(v-if="appliedValueByDate(date) !== valueByDate(date)")
        FaIcon.clock-applying(icon="clock")
      //- Manual mode
      ManualModePrice(
        v-if="isManualMode"
        :prev-date="formattedDates[index - 1]"
        :date="date"
        :next-date="formattedDates[index + 1]"
        :current-prices="currentPrices"
        :changed-prices="changedPrices"
        :price-class-names="priceClassName(date)"
        :rental-period="rentalPeriod.value"
        @mousedown="handleMouseDown(date)"
        @mouseover="handleMouseOver(date)"
        @mouseup="handleMouseUp"
      )
      .prices(
        v-else
        :class="{ selected: isSelectedDate(date) }"
      )
        .unchanging-prices(
          @mousedown="handleMouseDown(date)"
          @mouseover="handleMouseOver(date)"
          @mouseup="handleMouseUp"
        )
          .current-price(v-if="isDisplayCurrentPrice") {{ loading ? '...' : $n(valueByDate(date)) }}
          .recommended-price-wrapper(v-if="isDisplayRecommendedPrice")
            RecommendedPrice(
              :key="date"
              :current-price="valueByDate(date)"
              :recommended-price="recommendedPriceToDisplay(date)"
            )
        .applying-price-wrapper.input(v-if="isDisplayApplyingPrices")
          AppNumberInput(
            :class="priceClassName(date)"
            :value="loading ? '...' : inputPriceValue(date)"
            :max="Number.POSITIVE_INFINITY"
            :invalid="isInvalidPrice(date)"
            :disabled="!hasEditPermission('price_calendar')"
            @input="handleInputPrice($event, date)"
          )
</template>

<script>
  // mixins
  import withModal from "@/mixins/withModal"
  import withPermissions from "@/mixins/withPermissions"
  import priceManagementModule from "@/config/store/price_management"
  import withStoreModule from "@/mixins/withStoreModule"

  // components
  import PriceModal from "./PriceModal"

  // misc
  import { each, map, isNull, isUndefined, uniq, head, isNumber, keys, includes } from "lodash-es"
  import { getFormattedDateRange } from "@/helpers/dates"
  import { bus } from "@/config"
  import { isAutoApplyingRecommendedPrices, isManualMode, isAutoSetPrices } from "@/helpers/price-calendar"

  // changed prices - array of numbers
  // current prices - array of objects like { applied_value: 100, value: 100 }
  const numberToPriceObject = value => ({ applied_value: value, value })

  const getInitialPrice = (startDate, endDate, prices) => {
    const dateRange = getFormattedDateRange(startDate, endDate)
    const priceValues = map(dateRange, dateString => prices[dateString])

    if (uniq(priceValues).length > 1) {
      return numberToPriceObject(0)
    } else {
      const priceValue = head(priceValues)
      return isNumber(priceValue) ? numberToPriceObject(priceValue) : priceValue
    }
  }

  const priceManagementMixin = withStoreModule(priceManagementModule, {
    name: "priceManagement",
    readers: { loading: "loading" }
  })

  export default {
    components: {
      ManualModePrice: () => import("./ManualModePrice"),
      RecommendedPrice: () => import("./RecommendedPrice"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    props: {
      currentPlan: {
        type: Object,
        required: true
      },
      changedPrices: {
        type: Object,
        default: () => new Object()
      },
      invalidPrices: {
        type: Array,
        default: () => new Array()
      },
      formattedDates: Array,
      filters: Object,
      rentalPeriod: {
        type: Object,
        default: () => new Object()
      },
      competitors: Array,
      justUpdatedDates: {
        type: Set,
        default: () => new Set()
      }
    },

    mixins: [withModal, withPermissions, priceManagementMixin],

    mounted() {
      bus.on(`update-prices-${this.rentalPeriod.value}`, this.handleChangePrice)
    },

    beforeDestroy() {
      bus.off(`update-prices-${this.rentalPeriod.value}`, this.handleChangePrice)
    },

    data() {
      return {
        selectable: false,
        initialDate: null,
        startDate: null,
        endDate: null
      }
    },

    computed: {
      isManualMode,

      isLargeClass() {
        return this.isDisplayApplyingPrices && this.isDisplayCurrentPrice && this.isDisplayRecommendedPrice
      },

      currentPrices() {
        return this.currentPlan.prices[this.rentalPeriod.value]
      },

      recommendedPrices() {
        try {
          return this.currentPlan.recommended_prices.prices[this.rentalPeriod.value]
        } catch {
          return {}
        }
      },

      isApplyRecommendedPrices() {
        return isAutoApplyingRecommendedPrices()
      },

      isDisplayCurrentPrice() {
        return !isAutoSetPrices() && !isManualMode()
      },

      isDisplayRecommendedPrice() {
        return !isManualMode()
      },

      isDisplayApplyingPrices() {
        return !isAutoSetPrices()
      },

      isJoinedApplyingPriceCells() {
        return isManualMode()
      },

      interval({ startDate: start, endDate: end }) {
        if (isNull(start)) {
          return []
        } else if (start === end || isNull(end)) {
          return [start]
        } else {
          return getFormattedDateRange(start, end)
        }
      }
    },

    methods: {
      recommendedPriceToDisplay(date) {
        if (isAutoSetPrices()) {
          return this.isDateInvalid(date) ? this.valueByDate(date) : this.recommendedPrices[date]
        } else {
          return this.recommendedPrices[date]
        }
      },

      isDateInvalid(date) {
        return this.currentPlan.recommended_prices.invalid_dates.includes(date)
      },

      appliedValueByDate(date) {
        return this.currentPrices[date]?.applied_value || 0
      },

      valueByDate(date) {
        return this.currentPrices[date]?.value || 0
      },

      inputPriceValue(date) {
        // if date key exists in changesPrices object then this price was changes
        // and need to display it even price value is null
        if (includes(keys(this.changedPrices), date)) {
          return this.changedPrices[date]
        } else {
          return this.isRecommendedPriceDisplayed(date)
            ? Number(this.recommendedPrices[date] || 0)
            : this.valueByDate(date)
        }
      },

      isRecommendedPriceDisplayed(date) {
        return this.isApplyRecommendedPrices && !this.justUpdatedDates.has(date)
      },

      priceClassName(date) {
        return {
          selected: this.isSelectedDate(date),
          invalid: this.isInvalidPrice(date),
          "changed-manually": this.isPriceChangedManually(date),
          "changed-auto": this.isPriceChangedAuto(date)
        }
      },

      isPriceChangedManually(date) {
        return !isUndefined(this.changedPrices[date]) && this.changedPrices[date] !== this.appliedValueByDate(date)
      },

      isPriceChangedAuto(date) {
        return (
          !this.isManualMode &&
          this.changedPrices[date] === this.recommendedPrices[date] &&
          this.isPriceChangedManually(date)
        )
      },

      isInvalidPrice(date) {
        return this.invalidPrices.includes(date)
      },

      handleInputPrice(value, date) {
        this.emitChangePrices({ ...this.changedPrices, [date]: Number(value) })
      },

      handleMouseDown(date) {
        if (!this.hasEditPermission("price_calendar") || isAutoSetPrices()) return

        document.addEventListener("mouseup", this.handleMouseUp, { once: true })

        // handle case when user starts selecting of cell on one rental period
        // then finished on another rental period (mouse up)
        // and then returned to finish selecting on first rental period
        if (this.selectable) {
          this.handleMouseOver(date)
          return
        }

        this.initialDate = date
        this.startDate = date
        this.endDate = null
        this.selectable = true
      },

      handleMouseOver(date) {
        if (this.startDate !== null && this.selectable) {
          if (date < this.initialDate) {
            this.endDate = this.initialDate
            this.startDate = date
          } else {
            this.startDate = this.initialDate
            this.endDate = date
          }
        }
      },

      handleMouseUp() {
        // prevent open modal when user starts selecting on one rental period
        // and finished on another
        if (isNull(this.startDate)) return

        this.selectable = false
        this.initialDate = null

        // handle short click on one cell
        if (isNull(this.endDate)) {
          this.endDate = this.startDate
        }

        this.openModal()
        setTimeout(() => {
          this.startDate = null
          this.endDate = null
        }, 200)
      },

      isSelectedDate(date) {
        return this.interval.includes(date)
      },

      handleChangePrice(updatedPrices) {
        const prices = { ...this.changedPrices }

        each(updatedPrices, (price, date) => {
          const currentPrice = this.appliedValueByDate(date)

          if (currentPrice === price) {
            delete prices[date]
          } else {
            prices[date] = price
          }
        })

        this.emitChangePrices(prices)
      },

      emitChangePrices(prices) {
        this.$emit("change", { prices, period: this.rentalPeriod.value })
      },

      openModal() {
        this.$openModal({
          title: `${this.currentPlan.car_class_name} • ${this.rentalPeriod.title}`,
          component: PriceModal,
          size: "large",
          background: "gray",
          closeOnClick: false,
          closeOnEsc: false,
          props: {
            initialPrice: getInitialPrice(this.startDate, this.endDate, {
              ...this.currentPrices,
              ...this.changedPrices
            }),
            initialStartDate: new Date(this.startDate),
            initialEndDate: new Date(this.endDate),
            carClassId: this.currentPlan.car_class_id,
            rentalPeriod: this.rentalPeriod.value,
            filters: this.filters,
            currentPrices: this.currentPrices,
            recommendedPrices: this.recommendedPrices,
            changedPrices: this.changedPrices,
            competitors: this.competitors,
            reservations: this.currentPlan.reservations,
            currentPlan: this.currentPlan
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/price-calendar.sass"

  .rental-period-days
    background-color: $default-gray-light
    border-bottom: 1px solid $border-element-color
    border-right: 1px solid $default-purple-light-line
    display: flex
    height: 40px

    &.large
      height: 120px

      .by-day
        border-right: 1px solid #e9eff4

    .by-day
      position: relative
      align-items: center
      box-sizing: border-box
      display: flex
      flex-direction: column
      justify-content: center
      width: 90px
      overflow: hidden

      .applying-overlay
        width: 100%
        height: 100%
        position: absolute
        backdrop-filter: blur(3px)
        display: flex
        align-items: center
        justify-content: center
        color: transparentize($default-purple, 0.25)
        background: transparentize($default-white, 0.75)

        svg
          font-size: 1.25rem

          @keyframes rotate-tuda-suda
            0%
              transform: rotate(0deg)
            40%
              transform: rotate(-90deg)
            60%
              transform: rotate(-45deg)
            80%
              transform: rotate(135deg)
            100%
              transform: rotate(0deg)

          animation: rotate-tuda-suda 5s ease-in-out normal infinite

      .prices
        width: 100%
        transition: all 0.05s linear

        &:hover
          background: #e9eff4

        &.selected
          background: $default-purple-light

      .current-price,
      .recommended-price-wrapper,
      .applying-price-wrapper
        align-items: center
        cursor: default
        display: flex
        height: 40px
        justify-content: center
        user-select: none
        width: 100%

        &.input
          padding: 0 5px

          ::v-deep
            .app-number-input
              input
                border: 1px solid $default-purple-light-line
                border-radius: 4px
                color: $link-color
                text-align: center
                font-size: 1em
                height: 28px
                width: 100%

              &.changed-manually
                input
                  +changed-manually

              &.changed-auto
                input
                  +changed-auto


        .applying-price
          align-items: center
          background-color: $default-white
          border: 1px solid $default-gray
          border-radius: 5px
          cursor: pointer
          display: flex
          justify-content: center
          height: 70%
          margin: 0 5px
          user-select: none
          width: 100%

          &.changed-manually
            +changed-manually

          &.changed-auto
            +changed-auto

          &.invalid
            +invalid

          &.selected
            background-color: $default-purple
            color: $default-white

        .recommended-price
          height: 70%
          margin: 0 5px
</style>
